export function useAppUtils() {
  /**
   * returns user nemo/C1 role
   * @param {string} data Gigya data object
   */
  // @ts-ignore
  const getC1UserRole = (userData) => {
    if (userData?.data?.eduelt?.instituteRole?.length > 0) {
      const nemoInstitute = userData.data.eduelt.instituteRole.find(
        // @ts-ignore
        (institute) => institute.platform == 'nemo'
      );
      if (nemoInstitute) return nemoInstitute.role;
    } else if (userData?.data?.nemo?.role) {
      return userData.data.nemo.role;
    } else {
      return '';
    }
  };
  return {
    getC1UserRole
  };
}
